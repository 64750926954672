const userState = {
  authenticated: false,
  currentPage: '',
  user: {
    language: null,
    fullName: '',
    role: '',
    username: '',
    organizationName: '',
    showExportButton: '',
    groupReportsAvailable: '',
    dashboardLabel: '',
    overrideColor: '',
    dpaAccepted: false
  },
  sidIdentifier: ''
}

export const home = {
  namespaced: true,
  state: userState,
  actions: {
    setUserState ({ commit }, data) {
      commit('setUserState', data)
    },
    setStdSidIdentifier ({ commit }, sidIdentifier) {
      commit('setStdSidIdentifier', sidIdentifier)
    },
    setCurrentPage ({ commit }, data) {
      commit('setCurrentPage', data)
    }
  },
  mutations: {
    setUserState (state, data) {
      state.authenticated = data.authenticated
      state.user.language = data.language
      state.user.fullName = data.fullName
      state.user.role = data.role
      state.user.username = data.username
      state.user.organizationName = data.organizationName
      state.user.showExportButton = data.showExportButton
      state.user.groupReportsAvailable = data.groupReportsAvailable
      state.user.dashboardLabel = data.dashboardLabel
      state.user.overrideColor = data.overrideColor
      state.user.dpaAccepted = data.dpaAccepted
    },
    setStdSidIdentifier (state, sidIdentifier) {
      state.sidIdentifier = sidIdentifier
    },
    setCurrentPage (state, page) {
      state.currentPage = page
    }
  },
  getters: {
    StdSidIdentifier: (state) => {
      return state.sidIdentifier
    },
    userFullname: (state) => {
      return state.user.fullName
    },
    userRole: (state) => {
      return state.user.role
    },
    userIsAuthenticated: (state) => {
      return state.authenticated
    },
    userUsername: (state) => {
      return state.username
    },
    userOrganizationName: (state) => {
      return state.organizationName
    },
    showExportButton: (state) => {
      return state.showExportButton
    }
  }
}
