import Vue from 'vue'
import Router from 'vue-router'
import store from '../_store'

Vue.use(Router)

export const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('../components/Dashboard.vue')
    },
    {
      beforeEnter: guard,
      path: '/results',
      name: 'results',
      component: () => import('../components/Results.vue')
    },
    {
      beforeEnter: guard,
      path: '/bulkDownload',
      name: 'bulkDownload',
      component: () => import('../components/BulkDownload.vue')
    },
    {
      beforeEnter: guard,
      path: '/groupReports',
      name: 'groupReports',
      component: () => import('../components/GroupResults.vue')
    },
    {
      path: '/invalidSession',
      name: 'invalidSession',
      component: () => import('../components/InvalidSession.vue')
    },
    { path: '*', redirect: '/' }
  ]
})

function guard (to, from, next) {
  if (!store.state.home.authenticated) {
    next('/') // allow to enter route
  } else {
    next() // go to '/login';
  }
}
