<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div :class="[getOverrideColor]">
    <v-navigation-drawer :mini-variant.sync="mini" app class="side-bar-color" dark v-model="drawer">
      <v-list-item class="side-header-bar-color">
        <div v-if="mini">
          <img alt="Vue Material Admin" src="../assets/logo_icon_light.png" style="height: 29px;">
        </div>
        <div v-if="!mini">
          <img alt="Vue Material Admin" src="../assets/einsightsLogo.png" style="width:111px; height: 29px;">
        </div>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>$vuetify.icons.values.mdiAccount</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{this.$store.state.home.user.username}}</v-list-item-title>
          <v-list-item-subtitle>{{this.$store.state.home.user.organizationName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <router-link to="/">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon small>$vuetify.icons.values.mdiChartBar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('msg.sidebar_text_dashboard')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link to="/results">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon small>$vuetify.icons.values.mdiChartPie</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('msg.sidebar_text_results')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
<!--        <router-link v-if="isShowExportButtonEnabled" to="/bulkDownload">-->
<!--          <v-list-item link>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon small>$vuetify.icons.values.mdiFileDocument</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>{{$t('msg.sidebar_text_export_data')}}</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--        </router-link>-->
        <router-link v-if="isGroupReportsEnabled" to="/groupReports">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon small>$vuetify.icons.values.mdiFileDocument</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('msg.group_report')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-item v-if="!mini" class="mt-10">
          <v-list-item-content>
            <v-list-item-title>
              <div align="center" v-if="getAssessmentLogo()">
                <img :src="getAssessmentLogoImageUrl()" class="invertedImage" alt="logo"/>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { apiUrl, rootUrl } from '../_config'
import { EventBus } from '../_helpers'

export default {
  name: 'home',
  data () {
    return {
      show: false,
      drawer: true,
      mini: true,
      fav: true,
      menu: false,
      message: false,
      hints: true
    }
  },
  methods: {
    handleLogout: function () {
      window.location.href = rootUrl() + 'appLogout'
    },
    handleSideBar: function () {
      this.mini = !this.mini
    },
    handleSideBarMobile: function () {
      this.drawer = !this.drawer
    },
    getAssessmentLogoImageUrl: function () {
      return apiUrl() + 'media/logo/' + this.getAssessmentLogo()
    },
    getAssessmentLogo: function () {
      if (this.$cookies.get('sp_dashboard_inverted_assessment_logo') && this.$cookies.get('sp_dashboard_inverted_assessment_logo') !== null && this.$cookies.get('sp_dashboard_inverted_assessment_logo').trim() !== '') {
        return this.$cookies.get('sp_dashboard_inverted_assessment_logo')
      }
      return undefined
    }
  },
  computed: {
    isShowExportButtonEnabled: function () {
      return this.$store.state.home.user.showExportButton
    },
    isGroupReportsEnabled: function () {
      return this.$store.state.home.user.groupReportsAvailable
    },
    getOverrideColor () {
      return this.$store.state.home.user.overrideColor
    }
  },
  created () {
    EventBus.$on('Clicked', this.handleSideBar)
    EventBus.$on('Sidebar', this.handleSideBarMobile)
    let pageTitle = ''
    if (this.$cookies.get('sp_dashboard_product_name') && this.$cookies.get('sp_dashboard_product_name').trim() !== '') {
      pageTitle = this.$cookies.get('sp_dashboard_product_name')
    }
    document.title = pageTitle
  }
}

</script>
<style>
  .v-application .primary--text {
    color: white !important;
  }

  .v-application a {
    text-decoration: none;
  }

  .v-application .primary--text {
    color: white !important;
  }

  .invertedImage {
    max-height: 100px
  }

  .side-bar-color {
    background-color: #5660a5 !important;
  }

  .mitas .side-bar-color {
    background-color: #093a3e !important;
  }

  .side-header-bar-color {
    background-color: #5660a5;
  }

  .mitas .side-header-bar-color {
    background-color: #093a3e !important;
  }

  /*.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){*/
  /*  color: red !important;*/
  /*}*/
</style>
