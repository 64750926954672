<template>
    <div :class="[getOverrideColor]">
      <v-app-bar app class="header-background-color" dense dark flat>
        <div class="d-none d-lg-flex">
          <v-btn @click="emitChanges" icon>
            <v-icon>$vuetify.icons.values.mdiMenu</v-icon>
          </v-btn>
        </div>
        <div class="d-flex d-lg-none">
          <v-btn @click="emitChangesSidebar" icon >
            <v-icon>$vuetify.icons.values.mdiMenu</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="logout-button-color" @click="handleLogout"> <v-icon class="pr-1 mb-1">$vuetify.icons.values.mdiLogoutVariant</v-icon>{{$t('msg.logout')}}</v-btn>
      </v-app-bar>
    </div>
</template>

<script>
import { rootUrl } from '../_config'
import { EventBus } from '../_helpers'
export default {
  name: 'Header',
  methods: {
    handleLogout: function () {
      window.location.href = rootUrl() + 'appLogout'
    },
    emitChanges: function () {
      EventBus.$emit('Clicked')
    },
    emitChangesSidebar: function () {
      EventBus.$emit('Sidebar')
    }
  },
  computed: {
    getOverrideColor () {
      return this.$store.state.home.user.overrideColor
    }
  }
}
</script>
<style scoped lang="css">
  .header-background-color {
    background-color: #555767 !important;
  }
  .mitas .header-background-color {
    background-color: #153830 !important;
  }

  .logout-button-color {
    background-color: grey !important;
  }
</style>
