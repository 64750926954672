<template>
   <v-footer :absolute="false" class="footer"
             style="position: absolute;bottom: 0;width: 100%;">

     <div class="font-weight-regular">© 2017- {{ new Date().getFullYear() }} Global Psytech Sdn. Bhd.</div>
   </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
