import Vue from 'vue'
import vuetify from './_vuetify'
import App from './app/App.vue'
import { router, i18n, translationHelper, EventBus } from './_helpers'
import VueCookies from 'vue-cookies'
import store from './_store'
import 'vuetify/dist/vuetify.min.css'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'

extend('required', { ...required })
Vue.use({ Highcharts })
Vue.use(VueCookies)
Vue.config.productionTip = false
highchartsMore(Highcharts)
Vue.use(HighchartsVue)

export const dashboardMixIn = {
  methods: {
    $translatedErrorMessages: function (errors, name) {
      if (errors && Object.keys(errors).length > 0) {
        for (let [key] of Object.entries(errors)) {
          return this.$t(`msg.${name}.error.${key}`.toString().toLowerCase())
        }
      }
    },
    onStorageEvent: function (storageEvent) {
      if (!this.$store.getters['home/StdSidIdentifier']) {
        return
      }

      if (storageEvent.key === '_sp_dashboard_sid' && this.$store.getters['home/StdSidIdentifier'] !== storageEvent.newValue) {
        EventBus.$emit('page_invalid')
      }
    },
    disableRefreshAndBack: function (event) {
      if (event.keyCode === 8 || event.keyCode === 116 || (event.ctrlKey && event.keyCode === 82)) {
        let doPrevent = true
        let types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week']
        let d = event.srcElement || event.target
        let disabled = d.readOnly || d.disabled
        if (!disabled) {
          if (d.isContentEditable) {
            doPrevent = false
          } else if (d.tagName === 'input'.toUpperCase()) {
            let type = d.type
            if (type) {
              type = type.toLowerCase()
            }
            if (types.indexOf(type) > -1) {
              doPrevent = false
            }
          } else if (d.tagName === 'textarea'.toUpperCase()) {
            doPrevent = false
          }
        }
        if (doPrevent) {
          event.preventDefault()
          return false
        }
      }
    }
  },
  mounted () {
    window.addEventListener('storage', this.onStorageEvent)

    window.addEventListener('keydown', this.disableRefreshAndBack)

    if (this.$cookies.get('sp_dashboard_lang') && this.$cookies.get('sp_dashboard_lang').trim() !== '') {
      translationHelper.loadLanguageAsync(this.$cookies.get('sp_dashboard_lang').trim())
    }
  },
  created: function () {
    let pageTitle = ''
    if (this.$cookies.get('sp_dashboard_assessment_name') && this.$cookies.get('sp_dashboard_assessment_name').trim() !== '') {
      pageTitle = this.$cookies.get('sp_dashboard_assessment_name')
    }
    document.title = pageTitle
  }
}

Vue.mixin(dashboardMixIn)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  components: { App },
  template: '<App/>',
  data: {
    themeColor: 'blue'
  }
}).$mount('#app')
